import { NgModule } from "@angular/core";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// third party
import { CarouselModule } from "primeng/carousel";
import { ButtonModule } from "primeng/button";
import { SelectButtonModule } from "primeng/selectbutton";
import { InputMaskModule } from "primeng/inputmask";
import { DropdownModule } from "primeng/dropdown";
import { AccordionModule } from "primeng/accordion";
import { AgmCoreModule } from "@agm/core";
import { CalendarModule } from 'primeng/calendar';
import { PaginatorModule } from 'primeng/paginator';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ShareModule } from '@ngx-share/core';
import { CaptchaModule } from 'primeng/captcha';
import { DialogModule } from 'primeng/dialog';
import { SnotifyModule } from 'ng-snotify';
// first party
import { SharedModule } from "../shared/shared.module";
import { CoreModule } from "../core/core.module";
import { HttpClientModule } from '@angular/common/http';
import { StopClickPropagationModule } from '../directives/stop-click-propagation/stop-click-propagation.module';
import { SafeHtmlModule } from '../core/pipes/safe-html/safe-html.module';
import { ErrorComponent } from './error/error.component';

import { NgxWheelModule } from 'ngx-wheel';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CartComponent } from "./cart/cart.component";
import { CartCheckoutComponent } from './cart-checkout/cart-checkout.component';

@NgModule({
  declarations: [
    ErrorComponent,
    PrivacyPolicyComponent,
    CartComponent,
    CartCheckoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    CoreModule,
    CarouselModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ShareModule,
    StopClickPropagationModule,
    SafeHtmlModule,
    NgxWheelModule,
    SnotifyModule,
    DialogModule,
    AutoCompleteModule,
    CalendarModule,
    ButtonModule,
    SelectButtonModule,
    InputMaskModule,
    DropdownModule,
    AccordionModule,
    PaginatorModule,
    CaptchaModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyC37c-g2AXnDGNCEALL5G2uyLAQxxhRF1Q"
    })
  ],
  exports: [],
  providers: [CurrencyPipe]
})
export class PagesModule {}
