import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ErrorComponent } from './pages/error/error.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { CartComponent } from './pages/cart/cart.component';
import { CartCheckoutComponent } from './pages/cart-checkout/cart-checkout.component';


const websiteTitle = 'Terzius -';

const routes: Routes = [
  {
    path: 'carrinho',
    component: CartComponent,
    data: {title: websiteTitle + ' Carrinho'}
  },
  {
    path: 'politica-privacidade',
    component: PrivacyPolicyComponent,
    data: {title: websiteTitle + ' Política de Privacidade'}
  },
  {
    path: 'checkout/:id',
    component: CartCheckoutComponent,
    data: {title: websiteTitle + ' Checkout'},
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: '**',
    redirectTo: '/error'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
