import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";

import { Phone } from "../interfaces/phone";
import { Address } from "../interfaces/address";
import { Coupom } from "../interfaces/coupom";
import { Constants } from "../shared/constants.service";
import { Router } from "@angular/router";
import { ClientCart } from "../interfaces/clientCart";
import { RelatedCourse } from "../interfaces/related-course";
import { Installments, PayloadInstallments } from "../interfaces/installments";

@Injectable({ providedIn: "root" })
export class CoreService {
  apiBaseUrl;
  googleMapsApiKey;
  contentType = "application/json";
  urlCreditCard = "https://sandbox.moip.com.br";

  headers = {
    "Content-Type": "application/json",
  };

  constructor(private http: HttpClient, private _router: Router) {
    this.apiBaseUrl = Constants.apiBaseUrl;
    this.googleMapsApiKey = Constants.googleMapsApiKy;
  }

  login(params: any = {}): Observable<any> {
    return this.http
      .post<any>(this.apiBaseUrl + "/login", params)
      .pipe(retry(1), catchError(this._handleError));
  }

  getPhones(): Observable<Phone> {
    const httpOptions = {
      headers: this.headers,
    };

    return this.http
      .get<Phone>(this.apiBaseUrl + "/phones", httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  getBanners(): Observable<Phone> {
    const httpOptions = {
      headers: this.headers,
    };

    return this.http
      .get<Phone>(this.apiBaseUrl + "/banners", httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  getCourseAreas(): Observable<Phone> {
    const httpOptions = {
      headers: this.headers,
    };

    return this.http
      .get<Phone>(this.apiBaseUrl + "/course_areas", httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  searchCourses(params: any) {
    return this.http
      .post(this.apiBaseUrl + "/courses", params)
      .pipe(retry(1), catchError(this._handleError));
  }

  searchCoursesCombo(params: any) {
    return this.http
      .post(this.apiBaseUrl + "/courses-combo", params)
      .pipe(retry(1), catchError(this._handleError));
  }

  financibleCourses(params: any) {
    return this.http
      .post(this.apiBaseUrl + "/financiable", params)
      .pipe(retry(1), catchError(this._handleError));
  }

  getCourse(slug: string): Observable<any> {
    const httpOptions = {
      headers: this.headers,
    };

    return this.http
      .get<any>(this.apiBaseUrl + "/courses/" + slug, httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  getBlogCategories() {
    const httpOptions = {
      headers: this.headers,
    };

    return this.http
      .get(this.apiBaseUrl + "/blog_categories", httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  saveAssembleClass(params: any = {}): Observable<any> {
    return this.http
      .post<any>(this.apiBaseUrl + "/assemble_class", params)
      .pipe(retry(1), catchError(this._handleError));
  }

  talkToUs(params: any = {}): Observable<any> {
    return this.http
      .post<any>(this.apiBaseUrl + "/contact_us", params)
      .pipe(retry(1), catchError(this._handleError));
  }

  workWithUs(params: any = {}): Observable<any> {
    return this.http
      .post<any>(this.apiBaseUrl + "/work_with_us", params)
      .pipe(retry(1), catchError(this._handleError));
  }

  getTeam(): Observable<any> {
    const httpOptions = {
      headers: this.headers,
    };

    return this.http
      .get<any>(this.apiBaseUrl + "/team", httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  getAddress(params: any = {}): Observable<Address> {
    const httpOptions = {
      headers: this.headers,
      params: params,
    };

    return this.http
      .get<Address>(this.apiBaseUrl + "/address", httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  getCities(params: any = {}): Observable<any> {
    const httpOptions = {
      headers: this.headers,
      params: params,
    };

    return this.http
      .get<any>(this.apiBaseUrl + "/cities", httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  getProfessions() {
    const httpOptions = {
      headers: this.headers,
    };
    return this.http
      .get(this.apiBaseUrl + "/professions", httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  getSpecializations() {
    const httpOptions = {
      headers: this.headers,
    };

    return this.http
      .get(this.apiBaseUrl + "/specializations", httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  checkCoupom(params: any = {}): Observable<Coupom> {
    const httpOptions = {
      headers: this.headers,
      params: params,
    };

    return this.http
      .get<Coupom>(this.apiBaseUrl + "/coupom", httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  getCourseTypes(params: any = {}) {
    const httpOptions = {
      headers: this.headers,
      params: params,
    };

    return this.http
      .get(this.apiBaseUrl + "/course_types", httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  getCourseTypesPos(params: any = {}) {
    const httpOptions = {
      headers: this.headers,
      params: params,
    };

    return this.http
      .get(this.apiBaseUrl + "/course_types_pos", httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  getCourseGroups() {
    const httpOptions = {
      headers: this.headers,
    };

    return this.http
      .get(this.apiBaseUrl + "/course_groups", httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  getCourseGroupsPos() {
    const httpOptions = {
      headers: this.headers,
    };

    return this.http
      .get(this.apiBaseUrl + "/course_groups_pos", httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  getUser(params: any = {}): Observable<any> {
    const httpOptions = {
      headers: this.headers,
      params: params,
    };

    return this.http
      .get<any>(this.apiBaseUrl + "/user", httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  courseSubscription(params: any = {}): Observable<any> {
    return this.http
      .post<any>(this.apiBaseUrl + "/subscription", params)
  }

  recoveryPassword(params: any) {
    const httpOptions = {
      headers: this.headers,
      params: params,
    };

    return this.http
      .get(this.apiBaseUrl + "/forgot_password", httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  courseQueue(params: any) {
    return this.http
      .post<any>(this.apiBaseUrl + "/course_queue", params)
      .pipe(retry(1), catchError(this._handleError));
  }

  getPageBanner(params: any) {
    const httpOptions = {
      headers: this.headers,
      params: params,
    };

    return this.http
      .get(this.apiBaseUrl + "/internal_banners", httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  getOurClients(): Observable<any> {
    return this.http
      .get<any>(this.apiBaseUrl + "/our_clients", {})
      .pipe(retry(1), catchError(this._handleError));
  }

  getCourseTypeDetails(slug: string): Observable<any> {
    const httpOptions = {
      headers: this.headers,
    };

    return this.http
      .get<any>(this.apiBaseUrl + "/course_types/" + slug, httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  getCourseTypeDetailsPos(slug: string): Observable<any> {
    const httpOptions = {
      headers: this.headers,
    };

    return this.http
      .get<any>(this.apiBaseUrl + "/course_types_pos/" + slug, httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  getInterestCard(params: any) {
    const httpOptions = {
      headers: this.headers,
    };
    return this.http
      .post(this.apiBaseUrl + "/interest-card", params)
      .pipe(retry(1), catchError(this._handleError));
  }

  getInterestFinance(params: any) {
    const httpOptions = {
      headers: this.headers,
      params: params,
    };

    return this.http
      .get(this.apiBaseUrl + "/interest-finance", httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  getSeoTags(params: any = {}) {
    const httpOptions = {
      headers: this.headers,
      params: params,
    };

    return this.http
      .get(this.apiBaseUrl + "/seo", httpOptions)
      .pipe(retry(1), catchError(this._handleError));
  }

  private _handleError(error) {
    console.log('deu errooo!', error);
    let params = { erro: error.status };
    this._router.navigate(["/error"], { queryParams: params });
    return throwError(null);
  }

  getBlackFridayVoucher(params: any = {}): Observable<any> {
    return this.http
      .post<any>(this.apiBaseUrl + "/voucher-black-friday", params)
      .pipe(retry(1), catchError(this._handleError));
  }

  addToCartAbandonment(data: ClientCart): Observable<String> {
    return this.http.post<any>(this.apiBaseUrl + "/lead-capture", data);
  }

  getRelatedCourses(id: number): Observable<RelatedCourse[]> {
    return this.http.get<RelatedCourse[]>(`${this.apiBaseUrl}/courses-compre-tambem/${id}`);
  }

  getInstallments(params: PayloadInstallments): Observable<Installments[]>  {
    return this.http.post<Installments[]>(`${this.apiBaseUrl}/cart-interest-card`, params);
  }

  getTerms(params: any): Observable<any> {
    return this.http
      .post<any>(this.apiBaseUrl + "/terms", params)
      .pipe(retry(1), catchError(this._handleError));
  }
}
