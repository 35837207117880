import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// third party
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from "primeng/inputmask";
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { CaptchaModule } from 'primeng/captcha';
//first party
import { SectionTitleComponent } from "./section-title/section-title.component";
import { PageBannerComponent } from "./page-banner/page-banner.component";
import { FormValidationComponent } from './form-validation/form-validation.component';

import { StopClickPropagationModule } from '../directives/stop-click-propagation/stop-click-propagation.module';
import { LazyImgDirectiveModule } from "../directives/lazy-img-directive/lazy-img-directive.module";
import { CartItemCard } from './cart-item-card/cart-item-card.component';
import { ModalCrossDataComponent } from "./modal-cross-data/modal-cross-data.component";

@NgModule({
  declarations: [
    SectionTitleComponent,
    PageBannerComponent,
    FormValidationComponent,
    CartItemCard,
    ModalCrossDataComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    DialogModule,
    CalendarModule,
    InputMaskModule,
    AutoCompleteModule,
    DropdownModule,
    CaptchaModule,
    StopClickPropagationModule,
    LazyImgDirectiveModule
  ],
  exports: [
    SectionTitleComponent,
    PageBannerComponent,
    FormValidationComponent,
    CartItemCard,
    ModalCrossDataComponent
  ]
})
export class SharedModule {}
