import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Constants } from '../constants.service';

@Component({
  selector: 'cm-modal-cross-data',
  templateUrl: './modal-cross-data.component.html',
  styleUrls: ['./modal-cross-data-component.scss']
})
export class ModalCrossDataComponent implements OnInit {
  formQuiz: FormGroup = this.formBuilder.group({
    profession: [null, []],
    professional_registry: [{ value: null, disabled: true }, []],
    other_area_acting: [{ value: null, disabled: true }, []],
    professional_moment: [{ value: null, disabled: true }, []],
    area_acting: [{ value: null, disabled: true }, []],
    area_especialization: [null],
    year_graduation: [{ value: null, disabled: true }, []],
    interest_another_area: [null],
    interested_another_specialization: [null],
    interested_specialization: [null],
  });

  showRegistry: boolean = false;
  showYearGraduation: boolean = false;
  showTipo: boolean = false;
  showAreaActing: boolean = false;
  showNewEspecialization: boolean = false;
  showMoreAreas: boolean = false;
  showOthersAreaActing: boolean = false;
  isGeneralist: boolean = false;
  isAcademic: boolean = false;
  isMedic: boolean = false;
  isNurse: boolean = false;
  isOthers: boolean = false;
  isOthersAreas: boolean = false;

  areaActingMirror: any;
  areas_especialization: String[] = [];
  professions = [];
  specializations = [];

  types: any[] = [
    { name: "Residente" },
    { name: "Especialista" },
    { name: "Generalista" },
  ];
  listAreaActing: any[] = [
    { id: 1, name: "Acupuntura" },
    { id: 2, name: "Alergia e imunologia" },
    { id: 3, name: "Anestesiologia" },
    { id: 4, name: "Angiologia" },
    { id: 5, name: "Cardiologia" },
    { id: 6, name: "Cirurgia cardiovascular" },
    { id: 7, name: "Cirurgia da mão" },
    { id: 8, name: "Cirurgia de cabeça e pescoço" },
    { id: 9, name: "Cirurgia do aparelho digestivo" },
    { id: 10, name: "Cirurgia geral" },
    { id: 11, name: "Cirurgia oncológica" },
    { id: 12, name: "Cirurgia pediátrica" },
    { id: 13, name: "Cirurgia plástica" },
    { id: 14, name: "Cirurgia torácica" },
    { id: 15, name: "Cirurgia vascular" },
    { id: 16, name: "Clínica médica" },
    { id: 17, name: "Coloproctologia" },
    { id: 18, name: "Dermatologia" },
    { id: 19, name: "Endocrinologia e metabologia" },
    { id: 20, name: "Endoscopia" },
    { id: 21, name: "Gastroenterologia" },
    { id: 22, name: "Genética médica" },
    { id: 23, name: "Geriatria" },
    { id: 24, name: "Ginecologia e obstetrícia" },
    { id: 25, name: "Hematologia e hemoterapia" },
    { id: 26, name: "Homeopatia" },
    { id: 27, name: "Infectologia" },
    { id: 28, name: "Mastologia" },
    { id: 29, name: "Medicina de emergência" },
    { id: 30, name: "Medicina de família e comunidade" },
    { id: 31, name: "Medicina do trabalho" },
    { id: 32, name: "Medicina do tráfego" },
    { id: 33, name: "Medicina esportiva" },
    { id: 34, name: "Medicina física e reabilitação" },
    { id: 35, name: "Medicina intensiva" },
    { id: 36, name: "Medicina legal e perícia médica" },
    { id: 37, name: "Medicina nuclear" },
    { id: 38, name: "Medicina preventiva e social" },
    { id: 39, name: "Nefrologia" },
    { id: 40, name: "Neurocirurgia" },
    { id: 41, name: "Neurologia" },
    { id: 42, name: "Nutrologia" },
    { id: 43, name: "Oftalmologia" },
    { id: 44, name: "Oncologia clínica" },
    { id: 45, name: "Ortopedia e traumatologia" },
    { id: 46, name: "Otorrinolaringologia" },
    { id: 47, name: "Patologia" },
    { id: 48, name: "Patologia clínica/medicina laboratorial" },
    { id: 49, name: "Pediatria" },
    { id: 50, name: "Pneumologia" },
    { id: 51, name: "Psiquiatria" },
    { id: 52, name: "Radiologia e diagnóstico por imagem" },
    { id: 53, name: "Radioterapia" },
    { id: 54, name: "Reumatologia" },
    { id: 55, name: "Urologia" },
    { id: 55, name: "Outra" },
  ];

  listMedicoAcademico: any[] = [
    { name: "MBA de Gestão e Inovação em Saúde" },
    { name: "Cuidados Paliativos" },
    { name: "Emergências Pediátricas" },
    { name: "Geriatria" },
    { name: "Medicina Intensiva de Adulto" },
    { name: "Medicina de Emergência" },
    { name: "Medicina Intensiva Pediátrica e Neonatal" },
    { name: "Cardiologia e Medicina do Estilo de Vida" },
    { name: "Direito Médico" },
    { name: "Nutrologia" },
    { name: "Endocrinologia" },
    { name: "Neurologia" },
    { name: "Psiquiatria" },
    { name: "Transporte Aeromédico e Medicina Aeroespacial" },
  ];

  listEnfermeiroOutros: any[] = [
    { name: "MBA de Gestão e Inovação em Saúde" },
    { name: "Cuidados Paliativos" },
    { name: "Transporte Aeromédico e Medicina Aeroespacial" },
  ];

  listOthersAreaActing: any[] = [
    { name: "Fisioterapeuta" },
    { name: "Fonoaudiólogo" },
    { name: "Nutricionista" },
    { name: "Odontólogo" },
    { name: "Psicólogo" },
    { name: "Socorrista" },
    { name: "Técnico em Enfermagem" },
    { name: "Outra" },
  ];

  @Input() profession: string = "";
  @Output() closeModal = new EventEmitter();
  @Output() onSubmitFormData = new EventEmitter();

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this._getSpecializations();
    this._setProfession();
    this.exibeCampos();
  }

  _setProfession() {
    this.formQuiz.patchValue({
      profession: this.profession,
    });
  }

  private _getSpecializations() {
    this.specializations = Constants.specializations;
  }

  filterActingArea(event: any): void {
    let params = event.query;

    this.areaActingMirror = [];

    this.listAreaActing.forEach((course) => {
      if (course.name.toLowerCase().includes(params.toLowerCase())) {
        this.areaActingMirror.push({
          alias: course.name,
          name: course.name,
          id: course.id,
        });
      }
    });
  }

  exibeCampos(): void {
    const { profession } = this.formQuiz.value;

    this.isOthersAreas = false;
    this.isOthers = false;
    this.isNurse = false;
    this.isMedic = false;
    this.showYearGraduation = false;
    this.showRegistry = false;
    this.showTipo = false;
    this.isGeneralist = false;
    this.showAreaActing = false;
    this.isAcademic = false;
    this.showNewEspecialization = false;
    this.showMoreAreas = false;
    this.showOthersAreaActing = false;

    this.disableField("year_graduation");
    this.disableField("professional_moment");
    this.disableField("professional_registry");
    this.disableField("other_area_acting");
    this.disableField("area_acting");

    if (!profession.includes("ACADÊMICO")) {
      this.showRegistry = true;

      this.enableField("professional_registry");

      if (profession.includes("MÉDICO")) {
        this.formQuiz.get("professional_registry").setValue(null);
        this.formQuiz.get("professional_moment").setValue(null);
        this.formQuiz.get("area_acting").setValue(null);
        this.formQuiz.get("year_graduation").setValue(null);
        this.formQuiz.get("interested_specialization").setValue(null);

        this.showTipo = true;
        this.isMedic = true;

        this.enableField("professional_moment");
        this.enableField("area_acting");

        this.areas_especialization = [];
        this.areas_especialization = this.listMedicoAcademico;
      }

      if (profession.includes("ENFERMEIRO(A)")) {
        this.formQuiz.get("professional_registry").setValue(null);
        this.formQuiz.get("area_acting").setValue(null);
        this.formQuiz.get("year_graduation").setValue(null);
        this.formQuiz.get("interested_specialization").setValue(null);

        this.isGeneralist = true;
        this.showMoreAreas = true;
        this.isNurse = true;

        this.areas_especialization = [];
        this.areas_especialization = this.listEnfermeiroOutros;
      }

      if (profession.includes("OUTROS")) {
        this.formQuiz.get("professional_registry").setValue(null);
        this.formQuiz.get("area_acting").setValue(null);
        this.formQuiz.get("year_graduation").setValue(null);
        this.formQuiz.get("interested_specialization").setValue(null);
        this.isAcademic = false;
        this.showOthersAreaActing = true;
        this.showMoreAreas = true;
        this.isOthers = true;
        this.isNurse = false;
        this.isMedic = false;

        this.areas_especialization = [];

        this.enableField("other_area_acting");
      }
    } else {
      this.showYearGraduation = true;
      this.isAcademic = true;
      this.showMoreAreas = true;

      this.formQuiz.get("interested_specialization").setValue(null);
      this.formQuiz.get("professional_registry").setValue(null);
      this.formQuiz.get("area_acting").setValue(null);
      this.formQuiz.get("professional_moment").setValue(null);
      this.formQuiz.get("area_especialization").setValue(null);

      this.enableField("year_graduation");

      this.areas_especialization = [];
      this.areas_especialization = this.listMedicoAcademico;
    }
  }

  verificaTipo(event: any): void {
    const { name } = event.value;

    if (name != "Generalista") {
      this.showAreaActing = true;
      this.isGeneralist = false;
      this.showNewEspecialization = true;
      this.showMoreAreas = true;
      this.enableField("area_acting");
    } else {
      this.isGeneralist = true;
      this.showAreaActing = false;
      this.showNewEspecialization = false;
      this.showMoreAreas = true;
      this.disableField("area_acting");
    }
  }

  enableField(field: any): void {
    this.formQuiz.get(field).enable();
    this.formQuiz.get(field).setValidators(Validators.required);
  }

  disableField(field: any): void {
    this.formQuiz.get(field).disable();
    this.formQuiz.get(field).setValidators([]);
  }

  showNewAreas(event: any): void {
    const { name } = event.value;


    if (name.includes("Socorrista") || name.includes("Técnico")) {
      this.showMoreAreas = false;
      this.isAcademic = false;
      this.isOthersAreas = false;
    } else {
      this.isOthersAreas = true;
      this.isAcademic = false;
      this.areas_especialization = [];
      this.areas_especialization = this.listEnfermeiroOutros;
    }
  }

  onQuizSubmit() {
    this.onSubmitFormData.emit(this.formQuiz.value);
  }

  closeModalCrossData() {
    this.closeModal.emit();
  }
}
