import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { NgxHotjarModule } from 'ngx-hotjar';
import { NgxWheelModule } from 'ngx-wheel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import { CoreModule } from './core/core.module';
import { PagesModule } from './pages/pages.module';

//import { WheelComponent } from "./pages/black-friday/wheel/wheel.component"
registerLocaleData(localePt, "pt");

@NgModule({
  imports: [
    NgxWheelModule,

    NgxHotjarModule.forRoot("1177925"),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    PagesModule,
    SnotifyModule,
    AppModule,
    BrowserTransferStateModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "pt" },
    { provide: "SnotifyToastConfig", useValue: ToastDefaults },
    SnotifyService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {}
